import logo from './logo.svg';
import './App.css';
import pako from "pako";
import Buffer from "buffer"
import React, { useEffect, useState } from "react";
import {decode as base64_decode, encode as base64_encode} from 'base-64';


function toJson(data) {
  var unencoded = data.replaceAll("_", "+").replaceAll("-", "/")
  var jsonData = base64_decode(unencoded).split("").map((char) => char.charCodeAt(0))
  const decodedData = pako.inflate(jsonData, { to: "string" });
  return JSON.parse(decodedData)
}

function tableItem(title, value) {
  if (value == null || value == "") {
    return (<div></div>)
  }

  return (
    <tr>
      <td className='left'><b>{title}:</b></td>
      <td className='right'>{value}</td>
    </tr> 
  )
}

function listItem(value) {
  return (
    <tr>
      <td className='left'><b>&#8226;</b></td>
      <td className='right'>{value}</td>
    </tr> 
  )
}

function ptDetailsSection(data) {
  if (data.p == null) {
    return (<div></div>)
  }

  return (
    <div>
      <h2>Patient Details</h2>
      <br/>
      <table id='detailsTable'>
        {tableItem("Name", data.p.n)}
        {tableItem("Identity Number", data.p.i)}
        {tableItem("Address", data.p.a)}
        {tableItem("Email", data.p.e)}
        {tableItem("Phone Number", data.p.p)}
        {tableItem("Medical Aid", data.p.m)}
      </table>
    </div>
  )
}

function doctorDetailsSection(data) {
  if (data.d == null) {
    return (<div></div>)
  }

  return (
    <div>
      <h2>Doctor Details</h2>
      <br/>
      <table id='detailsTable'>
        {tableItem("Name", data.d.f)}
        {tableItem("Qualification", data.d.q)}
        {tableItem("Registration Number", data.d.r)}
        {tableItem("Practice Number", data.d.pn)}
        {tableItem("Practice Name", data.d.n)}
        {tableItem("Email", data.d.e)}
        {tableItem("Phone", data.d.p)}
        {tableItem("Address", data.d.a)}
        {tableItem("Extras", data.d.ex)}
      </table>
    </div>
  )
}

function buildMedications(data) {
  if (data.m == null) {
    return (<div></div>)
  }

  return (
    <div>
      <h2>Medications</h2>
      <br/>
      <table>
      { data.m.map(element => {
        return listItem(element);
      })}
      </table>
    </div>
  )
}

function buildScript(data) {
  if (data.p == null) {
    return (<div></div>)
  }

  return (
    <div id='body'>
      <div>
      <h1>Script for {data.p.n}</h1>
        <h3>Issued on: {data.t}</h3>
        <h3>Repeats: {data.r}</h3>
        <br/>
        {doctorDetailsSection(data)}
        <br/>
        {ptDetailsSection(data)}
        <br/>
        {buildMedications(data)}
      </div>
    </div>
  )
}

function buildSickNote(data) {
  if (data.p == null) {
    return (<div></div>)
  }

  return (
    <div id='body'>
      <div>
      <h1>Sick Note for {data.p.n}</h1>
        <h3>Issued on: {data.t}</h3>
        <h3>Days Off: {data.r}</h3>
        <br/>
        {doctorDetailsSection(data)}
        {ptDetailsSection(data)}
        {data.n != "" ? <h2>Notes</h2> : <div></div> }
        <p>{data.n}</p>
      </div>
    </div>
  )
}

function buildCertificate(data) {
  if (data.p == null) {
    return (<div></div>)
  }

  return (
    <div id='body'>
      <div>
      <h1>Medical Certificate for {data.p.n}</h1>
        <h3>Issued on: {data.t}</h3>
        <br/>
        {doctorDetailsSection(data)}
        {ptDetailsSection(data)}
        {data.n != "" ? <h2>Notes</h2> : <div></div> }
        <p>{data.n}</p>
      </div>
    </div>
  )
}

function buildBody(type, data) {
  switch(type) {
    case "p":
      return buildScript(data);
    case "s":
      return buildSickNote(data);
    default:
      return buildCertificate(data);
  }
}

function App() {
  const [data, setData] = useState({});
  const [type, setType] = useState("p")

  useEffect(( ) => {

    const urlSearchString = window.location.search;
   
    const params = new URLSearchParams(urlSearchString);
    setData(toJson(params.get('d')))
    setType(params.get('t'))
  }, [ ]);

  return (
    <div className="body">
      { buildBody(type, data) }
    </div>
  );
}

export default App;
